import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import abductionIllustration from '../images/abduction-illustration.svg'

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404: Not found" />

      <section className="max-w-4xl mx-auto py-6 md:py-20 px-6">
        <div className="lg:flex lg:justify-between lg:space-x-20">
          <div className="lg:w-1/2 max-w-xl mx-auto lg:mx-0 text-center lg:text-left mb-16 lg:mb-0">
            <h1 className="text-3xl sm:text-5xl font-black leading-tight mb-8">
              404: not found
            </h1>
            <h2 className="text-2xl">
              Looks like this page is a ghost that got abducted by aliens...
            </h2>
          </div>
          <div className="lg:w-1/2 max-w-xl mx-auto lg:mx-0 mb-16 lg:mb-24">
            <img
              alt="Ghost getting abducted by aliens"
              src={abductionIllustration}
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
